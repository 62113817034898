import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { TokenService, UserInfo } from './shared/token.service';
import { AuthService } from './shared/auth.service';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, map, shareReplay } from 'rxjs';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

    title = 'Floricode VRS';
    userInfo?: UserInfo;
    isLoggedIn: boolean = false;
    isModerator: boolean = false;
    isAdmin: boolean = false;

    private breakpointObserver = inject(BreakpointObserver);
    isSmall$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium]).pipe(
        map((result) => result.matches),
        shareReplay(),
    );

    @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;

    constructor(
        private router: Router,
        private tokenService: TokenService,
        private authService: AuthService,
    ) { }

    ngOnInit(): void {
        this.tokenService.tokenExpiredSubject$.subscribe(() => {
            this.updateUserState();
            this.router.navigate(['login']);
        });

        this.tokenService.accessToken$.subscribe(() => this.updateUserState());
    }

    toggleDrawer(): void {
        if (this.isLoggedIn)
            this.drawer.toggle();
    }

    login(): void {
        this.router.navigateByUrl('login');
    }

    logout(): void {
        this.authService.logout();
        this.updateUserState();
        this.router.navigateByUrl('login');
    }

    private updateUserState(): void {
        this.isLoggedIn = this.tokenService.isLoggedIn();
        this.userInfo = this.tokenService.getUserInfo();

        this.isAdmin = this.tokenService.isAdministrator();
        this.isModerator = this.tokenService.isModerator() || this.isAdmin;
    }
}