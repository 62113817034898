<mat-toolbar color="primary">
    <div *ngIf="isLoggedIn && isModerator" class="toggle-container">
        <button mat-icon-button aria-label="Toggle sidenav" (click)="toggleDrawer()">
            <mat-icon aria-label="Side nav toggle icon">apps</mat-icon>
        </button>
    </div>
    <a class="title" routerLink="packaging/items"><img src="assets/icon-floricode.svg" alt="Logo" class="logo"><span>{{ title }}</span></a>
    <span class="spacer"></span>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5">
        <mat-icon *ngIf="!isAdmin && isModerator" i18n-matTooltip matTooltip="Moderator">verified_user</mat-icon>
        <mat-icon *ngIf="isAdmin" i18n-matTooltip matTooltip="Admin">shield_person</mat-icon>
        <span>{{ userInfo?.name }}</span>
    </div>

    <button *ngIf="isLoggedIn;else btnLogin" mat-icon-button i18n-matTooltip matTooltip="Logout" (click)="logout()">
        <mat-icon>logout</mat-icon>
    </button>
    <ng-template #btnLogin>
        <button mat-button i18n-matTooltip matTooltip="Login" (click)="login()">
            <mat-icon>login</mat-icon><span i18n>Login</span>
        </button>
    </ng-template>
</mat-toolbar>

<mat-sidenav-container class="main-sidenav-container">
    <mat-sidenav #drawer class="sidenav" [mode]="(isSmall$ | async) ? 'over' : 'side'" [opened]="(isSmall$ | async) === false && isLoggedIn && isModerator">
        <mat-toolbar i18n>Menu</mat-toolbar>
        <mat-nav-list>
            <a *ngIf="isLoggedIn" mat-list-item routerLink="packaging/items" i18n>Items</a>
            <a *ngIf="isLoggedIn && isModerator" mat-list-item routerLink="packaging/types" i18n>Types</a>
            <a *ngIf="isLoggedIn && isModerator" mat-list-item routerLink="packaging/materials" i18n>Materials</a>
            <a *ngIf="isLoggedIn && isModerator" mat-list-item routerLink="packaging/characteristics" i18n>Characteristics</a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>